<template>
  <div class="main-full-content">
    <el-card class="box-card last_card">
      <div class="f-m-b-10">
        <span>当前位置：</span>
        <span class="aTagStyle" v-for="(item, index) in positionList" :key="index"
          @click="backHandleQuery(item,index)">{{item.name}}{{index !== positionList.length - 1 ? ' / ' : ''}}</span>
      </div>
      <div>
        <el-button
            type="primary"
            size="medium"
            @click="handleEdit(positionList.length == 1 ? null : positionList[positionList.length - 1],'add')"
        >新增目录</el-button>
        <el-button
            v-if="positionList.length !== 1"
            type="primary"
            size="medium"
            @click="handleFileEdit(positionList[positionList.length - 1],'add')"
        >上传文件</el-button>
        <el-button
            type="primary"
            size="medium"
            class="c-theme-error-button"
            @click="handleBatDel"
            :disabled="selectionRows.length === 0"
            v-if="buttonShowList.delB"
        >批量删除
        </el-button>
      </div>
      <f-table v-loading="loading" border ref="GTable" size="medium" row-key="id" class="c-th-has-bgcolor" :columns="columns"
        :data="tableData" :isorter="isorter" :cell-style="{ 'text-align': 'center' }" key="fileManage" tableHeight="66vh"
        @selection-change="handleSelectionChange" @sort-change="handleTableChange" @queryChange="handleTableQueryChange">
        <template v-slot="scope">

          <template v-if="scope.column.property === 'action'">
            <el-button
                type="text"
                v-if="scope.row.attribute == '0' && buttonShowList.scwj"
                @click="handleFileEdit(scope.row,'add')"
            >上传文件</el-button>
            <el-button
                type="text"
                v-if="scope.row.attribute == '0' && buttonShowList.szml"
                @click="handleEdit(scope.row,'add')"
            >新增目录</el-button>
            <el-button
                type="text"
                v-if="scope.row.isDelete == '1' && buttonShowList.bj"
                @click="handleEditAll(scope.row,'edit')"
            >编辑</el-button>
            <el-button
                type="text"
                v-if="scope.row.isDelete == '1' && buttonShowList.sc"
                @click="handleDel(scope.row)"
            >删除</el-button>
            <el-button
                type="text"
                v-if="scope.row.attribute == '1' && buttonShowList.xz"
                @click="downLoadFile(scope.row)"
            >下载</el-button>
            <!-- <el-button type="text" v-if="scope.row.attribute == '1'" @click="wenzhang(scope.row)">预览</el-button> -->
          </template>
          <template v-else-if="scope.column.property === 'departmentId'">
            {{parseDict(scope.row.departmentId, 'sys_depart')}}
          </template>
          <template v-else-if="scope.column.property === 'isOpen'">
            {{parseDict(scope.row.isOpen, 'is_delete')}}
          </template>
          <template v-else-if="scope.column.property === 'name'">
            <i :class="scope.row.attribute == '0' ? 'el-icon-folder-opened' : 'el-icon-document'"></i>
            <span class="aTagStyle" @click="nextHandleQuery(scope.row.id, scope.row.name,scope.row)">{{scope.row.name}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog :title="form.id ? '编辑目录' : '新增目录'" :visible.sync="edVis" width="60%" custom-class="c-custom-dialog"
      :close-on-click-modal="false" top="30px" v-if="edVis">
      <el-form :model="form" :rules="rules" ref="form" :label-width="labelWidthMenu" size="medium" class="addEditBox">
        <el-row>
          <el-col :span="24" v-if="form.pid">
            <el-form-item label="上级目录" :label-width="labelWidthMenu">
              <el-input v-model="form.pName" maxlength="50" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="name" label="目录名称" :label-width="labelWidthMenu">
              <el-input v-model="form.name" maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="!form.pid">
            <el-form-item prop="departmentId" label="部门" :label-width="labelWidthMenu">
              <f-select :isNeed="form.departmentId" v-model="form.departmentId" :dict="'sys_depart'" :disabled="form.id ? true : false">
              </f-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="medium" @click="edVis = false">取消</el-button>
        <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="fileForm.id ? '编辑文件' : '上传文件'" :visible.sync="edFileVis" width="60%" custom-class="c-custom-dialog"
      :close-on-click-modal="false" top="30px" v-if="edFileVis">
      <el-form :model="fileForm" :rules="rules" ref="fileForm" :label-width="labelWidthMenu" size="medium" class="addEditBox">
        <el-row>
          <el-col :span="24">
            <el-form-item label="上级目录" :label-width="labelWidthMenu">
              <el-input v-model="fileForm.dirName" maxlength="255" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="fileList" label="上传文件">
              <f-upload ref="fileManagement" :dir="'content'" :isUseOss="true" :multiple="fileForm.id ? false : true" :isLoading="false"
                :limit="fileForm.id ? 1 : null" @input="setFileList"></f-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="isOpen" label="是否公开" :label-width="labelWidthMenu">
              <f-select v-model="fileForm.isOpen" :isNeed="fileForm.isOpen" :dict="'is_default'">
              </f-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="medium" @click="edFileVis = false">取消</el-button>
        <el-button type="primary" size="medium" @click="handleSubmitFile">确定</el-button>
      </span>
    </el-dialog>
    <preview-img :visible="visibleFile" :imgUrl="imgUrl" :type="type" @setImgVisible="(val) => { visibleFile = val }"></preview-img>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins";
import { deleteAction, httpAction, postAction } from "@/api/manage";
export default {
  name: 'Home',
  mixins: [QueryMixins],
  components: {
  },
  data() {
    return {
      edVis: false,
      edFileVis: false,
      visibleFile: false, //文件预览
      rules: {
        name: [
          { required: true, message: "请输入目录名称", trigger: "blur" },
          { max: 255, message: "长度不能超过255", trigger: "change" },
        ],
        departmentId: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
        fileList: [{ required: true, message: "请上传文件", trigger: "change" }],
        isOpen: [{ required: true, message: "请选择是否公开", trigger: "change" }],
      },
      types: "",
      allArticleTypeList: [],
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "name",
          label: "目录/文件",
        },
        {
          prop: "departmentId",
          label: "所属部门",
        },
        {
          prop: "size",
          label: "文件大小",
        },
        {
          prop: "isOpen",
          label: "是否公开",
        },
        {
          prop: "action",
          label: "操作",
          width: 220,
        },
      ],

      form: {
        departmentId: "", // 部门id
        name: "", // 目录名称
        pid: "",  //父目录
        pName: "",  //父目录
      },
      fileForm: {
        fileList: [],
        isOpen: '',
        dirId: '',
        dirName: '',
      },
      url: {
        list: "/sys/filemanager/getList",
        getAllList: "/sys/storePublish/getAllList",
        isTop: "/sys/storePublish/isTop",
        delete: "/sys/filemanager/delete",
        deleteBatch: "/sys/storePublish/deleteBatch",
        add: "/sys/filemanager/addList",
        edit: "/sys/filemanager/edit",
        addFile: "/sys/filemanager/add",
        queryById: "/sys/storePublish/queryById",
        batchDownload: "/sys/oss/file/batchDownload",
        vagrantsNum: "/sys/managerFind/vagrantsNum",
      },
      isFind: true,
      attachFile: [],
      labelWidthMenu: '100px',
      dictCodes: ['sys_depart', 'is_delete'],
      positionList: [{ name: '文件管理', id: '' }],
      newPid: '', //  当前位置的pid
      imgUrl: '', //预览url
      type: ''
    };
  },
  methods: {
    backHandleQuery(item, index) {
      this.positionList = this.positionList.slice(0, index + 1);
      this.handleQuery(item.id);
    },
    nextHandleQuery(pid, name, row) {
      // 目录可点击下一层
      console.log(row, 'row');
      if (row.attribute == '0') {
        this.positionList.push({ id: pid, name: name });
        this.handleQuery(pid);
      } else {
        // 文件直接预览或者下载
        if (['jpg', 'jpeg', 'img', 'gif', 'png', 'pneg', 'doc', 'docx', 'xlsx', 'xls'].indexOf(row.type) == -1) {
          if (row.type == 'pdf') {
            window.open(row.url, "hello");
          } else {
            document.location.href = row.url;
          }
        } else {
          this.type = row.type;
          this.imgUrl = row.url;
          this.visibleFile = true;
        }
      }
    },
    // 下载文件
    downLoadFile({ type, name, url }) {
      if (type == 'pdf') {
        window.open(url)
      } else {
        document.location.href = url;
      }
    },
    async handleQuery(pid) {
      this.newPid = pid;
      this.loading = true
      let form = { ...this.queryForm, ...this.filterForm, ...this.initData }
      for (let item in form) {
        if (typeof form[item] === 'string') {
          form[item] = form[item].trim()
          if (form[item] === '') {
            form[item] = null
          }
        }
      }
      const { prop, order } = this.isorter
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
        pid: this.newPid
      }
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === 'startTime') {
          this.$set(params, 'column', 'startTime')
        } else if (this.enabledCreatetime) {
          this.$set(params, 'column', 'createTime')
        }
        //提货中查询
        if (this.queryForm.column === 'distributionTime') {
          this.$set(params, 'column', 'distributionTime')
        }
        //排序
        //提货中查询
        if (this.queryForm.column === 'sort') {
          this.$set(params, 'column', 'sort')
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage
        params.pageSize = this.ipagination.pageSize
      }
      await postAction(this.url.list, params).then((res) => {
        console.log(res)
        if (res.success) {
          this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
          if (res.result) {
            const { records, total } = res.result
            this.tableData = records || res.result;
            this.tableData.forEach((item, index) => {
              // 文件放到单独的字段里面
              if (item.attribute == '1') {
                item.fileList = [{
                  name: item.name,
                  id: item.ossId,
                  ext: item.type,
                  size: item.size,
                  url: item.url
                }]
              }
            })
            this.ipagination && (this.ipagination.total = total || res.result.length)
          } else {

            this.tableData = [];

            this.ipagination = 0;
          }

        }
      }).finally(() => {
        this.loading = false;
        this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
      })
    },
    // 编辑新增目录
    handleEdit(row, type) {
      if (type == 'add') {
        console.log('新增目录');
        this.form = {
          departmentId: "", // 部门id
          name: "", // 目录名称
          pid: row && row.id,  //父目录
          pName: row && row.name,
        };
      } else {
        console.log('编辑目录', row);
        this.form = JSON.parse(JSON.stringify(row));
        if (this.positionList.length !== 1) {
          this.form.pName = this.positionList[this.positionList.length - 1].name;
        }
      }
      this.edVis = true;
    },
    // 编辑上传文件
    handleFileEdit(row, type) {
      if (type == 'add') {
        console.log(row, '新增文件');
        this.fileForm = {
          fileList: [],
          isOpen: '',
          dirId: row && row.id,
          dirName: row && row.name,
        };
      } else {
        console.log('编辑文件');
        this.fileForm = JSON.parse(JSON.stringify(row));
        if (this.positionList.length !== 1) {
          this.fileForm.dirName = this.positionList[this.positionList.length - 1].name;
        }
      }
      this.edFileVis = true;
      this.$nextTick(() => {
        this.$refs.fileManagement ? this.$refs.fileManagement.fileList = this.fileForm.fileList : '';
      });
    },
    // 编辑文件列表
    setFileList(e) {
      this.$set(this.fileForm, 'fileList', e);
    },
    // 编辑按钮触发 attribute0是目录 1 是文件
    handleEditAll(row, type) {
      if (row && row.attribute == '0') {
        this.handleEdit(row, type);
      } else {
        this.handleFileEdit(row, type);
      }
    },
    // 保存提交目录
    handleSubmit() {
      let url = this.url.add;
      let method = "POST";
      let form = this.form;

      if (this.form.id) {
        url = this.url.edit;
        method = "PUT";
        form = {
          type: this.form.attribute,
          id: this.form.id,
          name: this.form.name,
          isOpen: this.form.isOpen
        };
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log("上传获取数据", form);
          httpAction(url, method, form).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.edVis = false;
            this.handleQuery(this.form.pid);
          });
        }
      });
    },
    // 保存提交文件
    handleSubmitFile() {
      let url = this.url.addFile;
      let method = "POST";
      let form = null;
      this.$refs.fileForm.validate((valid) => {
        if (valid) {
          if (this.fileForm.id) {
            url = this.url.edit;
            method = "PUT";
            if (this.fileForm && this.fileForm.fileList && this.fileForm.fileList.length) {
              form = {
                type: this.fileForm.attribute,
                id: this.fileForm.id,
                name: this.fileForm.fileList[0].name,
                isOpen: this.fileForm.isOpen,
                ossId: this.fileForm.fileList[0].ossId,
                size: this.fileForm.fileList[0].size,
                url: this.fileForm.fileList[0].url
              };
            }
          } else {
            form = [];
            this.fileForm && this.fileForm.fileList && this.fileForm.fileList.forEach((item, index) => {
              let fileItem = {
                dirId: this.fileForm.dirId,
                fileName: item.name,
                isOpen: this.fileForm.isOpen,
                ossId: item.id,
                type: item.ext,
                size: item.size,
                url: item.url
              }
              form.push(fileItem);
            })
          }
          console.log("上传获取数据", form);
          httpAction(url, method, form).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.edFileVis = false;
            this.handleQuery(this.fileForm.dirId);
          });
        }
      });
    },
    // 删除文件
    handleDel({ id, name, attribute, pid }) {
      this.$confirm(`确定删除${name}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          let delForm = [{ attribute: attribute, id: id }];

          deleteAction(this.url.delete, null, delForm).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery(pid);
          });
        })
        .catch(() => { });
    },
    handleBatDel() {
      // let isDelete = false;
      // let isAdmin = false;
      // let ss = localStorage.getItem("USERINFO");
      // if (ss) {
      //   let user = JSON.parse(ss);
      //   if (user && user.username == 'admin') {
      //     isAdmin = true;
      //   }
      // }
      // if (!isAdmin) {
      //   this.selectionRows.forEach((item, index) => {
      //     if (item.isDelete == '0') {
      //       isDelete = true;
      //     }
      //   })
      // }
      // if (isDelete) {
      //   this.$message({
      //     type: "warning",
      //     message: "勾选了没有权限删除的文件，请重新选择",
      //   });
      //   return;
      // }
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, null, this.selectionRows).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery(this.selectionRows[0].pid);
          });
        })
        .catch(() => { });
    },
    created() {

    },
  },

  computed : {
    buttonShowList() {
      return {
        'delB':this.$btnIsShow('dashboard/fileManagement','0','批量删除'),
        'scwj':this.$btnIsShow('dashboard/fileManagement','0','上传文件'),
        'szml':this.$btnIsShow('dashboard/fileManagement','1','新增目录'),
        'bj':this.$btnIsShow('dashboard/fileManagement','1','编辑'),
        'sc':this.$btnIsShow('dashboard/fileManagement','1','删除'),
        'xz':this.$btnIsShow('dashboard/fileManagement','1','下载'),
      }
    }
  },
  watch: {
  },

}
</script>
<style lang="scss" scoped>
.el-icon-folder-opened {
  color: #00a0e9;
  font-size: 25px;
  padding: 0 5px 0 15px;
}
.el-icon-document {
  // color: #00a0e9;
  font-size: 25px;
  padding: 0 5px 0 15px;
}
</style>
